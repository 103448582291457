import tw from 'twin.macro';
import css from '@emotion/css';
import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export const Heading = ({ level, children, ...props }) => {
  return React.createElement('h'.concat(level), props, children);
};

export const BtnPrimary = tw.a`
  text-center bg-yellow-bright hover:bg-khaki active:bg-golden border-0 text-h4 text-blue-marine font-gilroy font-extrabold py-3 px-6 rounded-full cursor-pointer no-underline inline-block
`;

export const BtnLinkPrimary: React.FC<{
  style?: React.CSSProperties;
  className?: string;
  to?: string;
  target?: string;
}> = ({ children, style, className, to, target }) => (
  <Link
    tw="text-center bg-yellow-bright hover:bg-khaki active:bg-golden font-gilroy border-0 text-blue-marine font-semibold py-3 px-6 rounded-full cursor-pointer no-underline inline-block"
    to={to}
    style={style}
    className={className}
    target={target}
  >
    {children}
  </Link>
);

export const BtnLinkScrollPrimary: React.FC<{
  className?: string;
  to?: string;
}> = ({ children, className, to }) => (
  <AnchorLink
    tw="text-center bg-yellow-bright hover:bg-khaki active:bg-golden border-0 text-blue-marine font-semibold py-3 px-6 rounded-full cursor-pointer no-underline inline-block"
    to={to}
    className={className}
  >
    {children}
  </AnchorLink>
);

export const HyperlinkPrimary: React.FC<{
  style?: React.CSSProperties;
  className?: string;
  href?: string;
  to?: string;
  target?: string;
  onClick?: any;
}> = ({ children, style, className, href, to, target, onClick }) =>
  href ? (
    <a
      href={href}
      target={target}
      tw="text-yellow-bright hover:text-blue-lapis underline font-normal"
      style={style}
      className={className}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <Link
      tw="text-yellow-bright hover:text-blue-lapis underline font-normal"
      to={to}
      style={style}
      className={className}
      onClick={onClick}
    >
      {children}
    </Link>
  );

export const HyperlinkSecondary: React.FC<{
  style?: React.CSSProperties;
  className?: string;
  href?: string;
  to?: string;
  target?: string;
}> = ({ children, style, className, href, to, target }) =>
  href ? (
    <a
      href={href}
      target={target}
      tw="text-blue-lapis hover:text-yellow-bright underline font-normal"
      style={style}
      className={className}
    >
      {children}
    </a>
  ) : (
    <Link
      tw="text-blue-lapis hover:text-yellow-bright underline font-normal"
      to={to}
      style={style}
      className={className}
    >
      {children}
    </Link>
  );

export const Ribbon: React.FC<{ style?: React.CSSProperties; className?: string }> = ({
  children,
  style,
  className,
}) => (
  <div
    tw="text-white px-2 py-1 flex items-center relative mb-2 rounded-sm"
    style={style}
    className={className}
    css={css`
      &:after {
        position: absolute;
        content: '';
        width: 17px;
        height: 17px;
        border-width: 0;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        right: -12px;
        ${tw`bg-white dark:bg-black-300`}
      }
    `}
  >
    <span tw="text-b3">{children}</span>
  </div>
);

export const RibbonCurvedLeft: React.FC<{ style?: React.CSSProperties; className?: string }> = ({
  children,
  style,
  className,
}) => (
  <div
    tw="text-white flex items-center relative mb-2"
    style={style}
    className={className}
    css={css`
      .ribbonSpan {
        white-space: pre;
        &:after {
          position: absolute;
          content: '';
          width: 17px;
          height: 17px;
          border-width: 0;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          right: -9px;
          ${tw`bg-white dark:bg-black-300`}
        }
      }
      .ribbon {
        position: absolute;
        top: 0px;
        ${tw`py-1 pl-2 pr-5`}
        ${tw`left-2 lg:left-4`}
      }
      .ribbon:before,
      .ribbon:after {
        content: '';
        position: absolute;
      }
      .ribbon:before {
        width: 7px;
        height: 120%;
        top: 0;
        left: -7px;
        padding: 0 0 7px;
        background: inherit;
        border-radius: 5px 0 0 5px;
      }
      .ribbon:after {
        width: 5px;
        height: 4px;
        bottom: -4px;
        left: -5.5px;
        border-radius: 5px 0 0 5px;
      }
    `}
  >
    <div className="ribbon" tw="text-b3">
      <span className="ribbonSpan">{children}</span>
    </div>
  </div>
);

export const RibbonCurvedRight: React.FC<{ style?: React.CSSProperties; className?: string }> = ({
  children,
  style,
  className,
}) => (
  <div
    tw="text-white flex items-center relative mb-2"
    style={style}
    className={className}
    css={css`
      .ribbonSpan {
        white-space: pre;
      }
      .ribbon {
        position: absolute;
        top: 0px;
        ${tw`rounded-l-sm`}
        ${tw`py-1 px-2`}
        ${tw`right-2`}
      }
      .ribbon:before,
      .ribbon:after {
        content: '';
        position: absolute;
      }
      .ribbon:before {
        width: 7px;
        height: 120%;
        top: 0;
        right: -6.5px;
        padding: 0 0 7px;
        background: inherit;
        border-radius: 5px 0 0 5px;
        transform: rotate(180deg);
      }
      .ribbon:after {
        width: 5px;
        height: 4px;
        bottom: -4px;
        right: -5.5px;
        border-radius: 5px 0 0 5px;
        transform: rotate(180deg);
      }
    `}
  >
    <div className="ribbon" tw="text-b3">
      <span className="ribbonSpan">{children}</span>
    </div>
  </div>
);

export const ShadowBox: React.FC<{
  style?: React.CSSProperties;
  className?: string;
  id?: string;
  hideDashed?: boolean;
}> = ({ children, style, className, id, hideDashed = false }) => (
  <div id={id} style={style} className={className}>
    <div tw="relative">
      <div
        tw="bg-transparent w-full h-full p-2 rounded-3xl flex flex-col justify-between absolute"
        css={css`
          top: 0;
          left: 0;
          width: calc(100% - 12px);
          height: 100%;
          ${hideDashed ? '' : 'border: 2px dashed #27282e;'}
        `}
      >
        <div
          tw="bg-blue-lapis p-2 rounded-3xl text-white"
          css={css`
            visibility: hidden;
          `}
        >
          {children}
        </div>
      </div>
      <div
        tw="bg-white h-full p-2 rounded-3xl flex flex-col justify-between"
        css={css`
          width: calc(100% - 12px);
          ${hideDashed ? '' : 'box-shadow: 12px 12px 0px 0px #27282e;'}
        `}
      >
        {hideDashed ? (
          <div tw="bg-transparent text-left rounded-2xl relative w-full py-1 lg:py-3 px-8 lg:px-24">
            {children}
          </div>
        ) : (
          <div tw="bg-transparent text-left rounded-2xl relative w-full py-6 lg:py-12 px-8 lg:px-24">
            {children}
          </div>
        )}
      </div>
    </div>
  </div>
);
