import React from 'react';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import Img, { FluidObject } from 'gatsby-image';
import { DateTime } from 'luxon';
import Obfuscate from 'react-obfuscate';
import { BtnPrimary, HyperlinkPrimary } from './scaffolds';

interface FooterPadiUmkmProps {
  logo?: FluidObject;
  whatsappLink?: string;
}

const FooterPadiUmkm: React.FC<FooterPadiUmkmProps> = (props: FooterPadiUmkmProps) => {
  return (
    <footer tw="text-white">
      <div tw="bg-blue-black px-4">
        <div tw="container">
          <div tw="pt-12 lg:mx-32">
            <Img
              tw="mb-4"
              fluid={props.logo as FluidObject}
              css={css`
                width: 130px;
                @media (max-width: 1024px) {
                  width: 68px;
                }
              `}
            />
          </div>
          <div tw="grid grid-cols-none lg:grid-cols-2 gap-12 pb-12 lg:mx-32">
            <div>
              <p tw="text-sm">
                Easybiz adalah anak perusahaan{' '}
                <HyperlinkPrimary href="https://hukumonline.com">Hukumonline.com</HyperlinkPrimary>{' '}
                yang menawarkan solusi kemudahan, kenyamanan dan legalitas dalam berbisnis di
                Indonesia. Kami memberikan layanan pendirian badan usaha dan perizinan untuk UKM dan
                startup di Indonesia mulai dari proses pendirian PT, domisili, hingga pengurusan
                izin-izin yang diperlukan untuk memulai usaha.
              </p>
              <hr
                css={css`
                  height: 1px;
                  border: none;
                  border-top: 1px dashed white;
                `}
              />
              <div tw="text-sm">
                PT Justika Solusi Indonesia, AD Premier Office Park Lantai 9 Jl. TB Simatupang No. 5
                Ragunan, Pasar Minggu Jakarta <br />
                <div
                  tw="flex flex-wrap"
                  css={css`
                    a {
                      ${tw`text-yellow-bright`}
                      &:hover {
                        ${tw`text-blue-lapis`}
                      }
                    }
                  `}
                >
                  <div>
                    <span tw="mr-1">📞:</span>{' '}
                    <Obfuscate linkText="/" className="gtm-contact-phone" tw="mr-1" tel={'0816-17-369-369'} />{' '}
                  </div>
                  <div>
                    <span tw="mr-1">/</span>{' '}
                    <Obfuscate linkText="/" className="gtm-contact-phone" tw="mr-1" tel={'021-227-089-03'} /> -{' '}
                  </div>
                  <div>
                    <span tw="mr-1">📧:</span>{' '}
                    <Obfuscate linkText="/" className="gtm-contact-email" tw="mr-1" email={'halo@easybiz.id'} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p>
                Selama pandemi COVID-19, semua konsultasi dan pengiriman dokumen dilakukan secara
                online.
              </p>
              <BtnPrimary
                className="gtm-contact-wa"
                href={props.whatsappLink || '/hubungi-kami-via-whatsapp'}
                target="__blank"
              >
                WhatsApp Kami Di Sini
              </BtnPrimary>
            </div>
          </div>
        </div>
      </div>
      <div tw="bg-blue-black-100 px-4">
        <div tw="container pt-10 pb-5 lg:py-10 text-sm">
          <div tw="lg:mx-32 flex flex-col-reverse lg:flex-row lg:items-center lg:justify-between">
            <div>Copyright &copy; {DateTime.local().year} Easybiz | All Rights Reserved</div>
            <div tw="lg:flex items-center">
              <p tw="text-b1 mb-4 lg:mb-0 font-gilroy lg:mr-2">Ikuti Kami di</p>
              <div
                tw="flex flex-wrap mb-8 lg:mb-0"
                css={css`
                  a {
                    background: white;
                    padding: 12.5px;
                    border-radius: 16px;
                    font-size: 21px;
                    line-height: 21px;
                    ${tw`text-blue-black`}
                    text-decoration: none;
                    display: block;
                    height: 46px;
                    width: 46px;
                    text-align: center;
                    margin-right: 16px;
                    margin-bottom: 0px;
                  }
                `}
              >
                <a
                  href="https://www.facebook.com/easybizID"
                  target="__blank"
                  aria-label="ikuti facebook easybiz"
                >
                  <span className="icon icon-facebook"></span>
                </a>
                <a
                  href="https://www.instagram.com/easybiz.id/?hl=en"
                  target="__blank"
                  aria-label="ikuti instagram easybiz"
                >
                  <span className="icon icon-instagram"></span>
                </a>
                <a
                  href="https://twitter.com/easybizid"
                  target="__blank"
                  aria-label="ikuti twitter easybiz"
                >
                  <span className="icon icon-twitter"></span>
                </a>
                <a
                  href="https://www.linkedin.com/company/easybiz"
                  target="__blank"
                  aria-label="ikuti linkedin easybiz"
                >
                  <span className="icon icon-linkedin"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterPadiUmkm;
