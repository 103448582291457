import React from 'react';
import { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import HeaderPadiUmkm from '../components/header-padi-umkm';
import Footer from '../components/footer';
import FooterPadiUmkm from '../components/footer-padi-umkm';
import FooterEng from '../components/footer-eng';
import { graphql, StaticQuery } from 'gatsby';
import { GetHeaderDataQuery } from '../graphql-types';
import Analytics from '../module/analytics';
import { utmType } from '../types/utmType';
import { getCookies } from '../utils/getcookies';
interface BaseCompProps {
  logo?: FluidObject;
  pathname?: string;
  hideHeader?: boolean;
  hideHeadbump?: boolean;
  whatsappLink?: string;
  additionalLogo?: FluidObject;
  fixedHeader?: boolean;
  data?: GetHeaderDataQuery;
  lang?: string;
}

interface BaseState {
  dark?: boolean;
}

class BaseLayoutComp extends React.Component<BaseCompProps, BaseState> {
  constructor(props: BaseCompProps) {
    super(props);
    let dark = false;
    if (typeof window !== 'undefined') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        dark = true;
      }
    }
    this.state = {
      dark,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.getUtmData();

      const gfont = document.createElement('link');
      gfont.setAttribute(
        'href',
        'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap',
      );
      gfont.setAttribute('rel', 'preload');
      gfont.setAttribute('as', 'style');
      gfont.setAttribute('onload', `this.rel='stylesheet'`);
      document.head.append(gfont);
    }
  }

  listenOsDarkMode() {
    if (typeof window !== 'undefined') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')) {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
          this.setState({
            dark: e.matches,
          });
        });
      }
    }
  }

  getUtmData() {
    const cookieValue = getCookies('utmdt');
    if (!cookieValue) {
      const analyticsData = Analytics();
      const utmCookies: utmType = {
        source: analyticsData.source,
        medium: analyticsData.medium,
        utmCampaign: analyticsData?.campaign,
        utmContent: analyticsData?.content,
        utmMedium: analyticsData?.medium,
        utmSource: analyticsData?.source,
        utmTerms: analyticsData?.terms,
      };

      document.cookie = `utmdt=${JSON.stringify(utmCookies)};max-age=300;domain=${
        window.location.hostname
      }`;
    }
  }

  render() {
    this.listenOsDarkMode();

    const data = this.props.data;
    const headbump = data.headbump;
    const dirjenLogo = data.dirjenLogo?.childrenImageSharp[0]?.fluid;

    return (
      <div>
        <Helmet htmlAttributes={{ lang: 'id' }}>
          <title></title>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            rel="sitemap"
            type="application/xml"
            href={`${process.env.PROXY_URL ?? ''}/sitemap.xml`}
          />
          <link
            href={`${process.env.CDN_URL ?? process.env.BASE_URL ?? ''}/styles/fonts.css`}
            rel="stylesheet"
          />
          <body className={this.state.dark ? 'dark' : ''} />
        </Helmet>
        {!this.props.hideHeader ? (
          <Header
            logo={this.props.logo}
            pathname={this.props.pathname}
            fixed={this.props.fixedHeader}
            headbump={headbump}
            hideHeadbump={this.props.hideHeadbump}
          />
        ) : (
          <HeaderPadiUmkm
            logo={this.props.logo}
            additionalLogo={this.props.additionalLogo}
            pathname={this.props.pathname}
          />
        )}
        {this.props.children}
        {!this.props.hideHeader ? (
          this.props.lang === 'en' ? (
            <FooterEng
              logo={this.props.logo}
              whatsappLink={this.props.whatsappLink}
              dirjenLogo={dirjenLogo}
            />
          ) : (
            <Footer
              logo={this.props.logo}
              whatsappLink={this.props.whatsappLink}
              dirjenLogo={dirjenLogo}
            />
          )
        ) : (
          <FooterPadiUmkm logo={this.props.logo} whatsappLink={this.props.whatsappLink} />
        )}
      </div>
    );
  }
}

interface BaseProps {
  logo?: FluidObject;
  pathname?: string;
  hideHeader?: boolean;
  hideHeadbump?: boolean;
  whatsappLink?: string;
  additionalLogo?: FluidObject;
  fixedHeader?: boolean;
  lang?: string;
}

const BaseLayout: React.FC<BaseProps> = ({
  logo,
  pathname,
  hideHeader,
  hideHeadbump,
  whatsappLink,
  additionalLogo,
  fixedHeader,
  children,
  lang,
}) => {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <BaseLayoutComp
          data={data}
          logo={logo}
          pathname={pathname}
          hideHeader={hideHeader}
          hideHeadbump={hideHeadbump}
          whatsappLink={whatsappLink}
          additionalLogo={additionalLogo}
          fixedHeader={fixedHeader}
          children={children}
          lang={lang}
        />
      )}
    />
  );
};

export default BaseLayout;

export const query = graphql`
  query getHeaderData {
    headbump: contentfulHeadbump {
      backgroundColor
      textColor
      hyperlinkText
      hyperlinkUrl
      hyperlinkColor
      text
      # eventLogo {
      #   file {
      #     url
      #     fileName
      #   }
      #   fluid(maxHeight: 1000) {
      #     ...ContentfulImageFluidFields
      #   }
      # }
    }
    dirjenLogo: file(relativePath: { eq: "images/dirjen.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 120) {
          ...FileImageSharpFluid
        }
      }
    }
  }
`;
