import React from 'react';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

interface HeaderPadiUmkmProps {
  logo?: FluidObject;
  pathname?: string;
  additionalLogo?: FluidObject;
}

interface HeaderPadiUmkmState {
  layananActive?: boolean;
  peraturanActive?: boolean;
  sidemenuActive?: boolean;
  offset?: number;
}

class HeaderPadiUmkm extends React.Component<HeaderPadiUmkmProps, HeaderPadiUmkmState> {
  constructor(props: HeaderPadiUmkmProps) {
    super(props);
    this.state = {
      layananActive: false,
      peraturanActive: false,
      sidemenuActive: false,
      offset: 0,
    };
  }

  handleScroll() {
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        this.setState({
          offset: window.scrollY,
        });
      };
    }
  }

  resetMenu() {
    this.setState({
      layananActive: false,
      peraturanActive: false,
      sidemenuActive: false,
    });
  }

  render() {
    this.handleScroll();
    return (
      <header
        css={css`
          background: transparent;
          position: relative;
          @media (max-width: 1024px) {
            position: fixed;
            z-index: 99999;
            width: 100%;
            top: 0;
          }
          .dropdown-enter {
            opacity: 0;
            transform: translateY(-20px);
          }
          .dropdown-enter-active {
            opacity: 1;
            transform: translateY(0);
            transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1),
              transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
          }
          .dropdown-exit {
            opacity: 1;
          }
          .dropdown-exit-active {
            opacity: 0;
            transform: translateY(-20px);
            transition: opacity 0.1s cubic-bezier(0.16, 1, 0.3, 1),
              transform 0.1s cubic-bezier(0.16, 1, 0.3, 1);
          }
        `}
      >
        <div
          tw="w-full flex items-center text-white"
          css={css`
            position: absolute;
            z-index: 9999;
            top: 0;
            height: 69px;
            transition: background-color 300ms;
            @media (min-width: 1024px) {
              ${this.state?.layananActive || this.state?.peraturanActive || this.state?.offset > 48
                ? tw`bg-blue-black`
                : tw`bg-transparent`}
              &:hover {
                ${tw`bg-blue-black`}
              }
              ${this.state?.offset > 0 ? tw`top-0` : tw``}
              ${this.state?.offset > 0 ? tw`fixed` : tw`absolute`}
            }

            @media (max-width: 1024px) {
              ${tw`bg-blue-black`}
            }
          `}
        >
          <div tw="container mx-4 lg:mx-auto flex items-center w-full">
            <Link tw="lg:ml-32" to="/layanan/paket-pendirian-pt-padi-umkm" aria-label="beranda">
              <Img
                fluid={this.props.logo as FluidObject}
                css={css`
                  margin-right: 10px;
                  position: relative;
                  width: 68px;
                  bottom: 5px;
                  @media (min-width: 1024px) {
                    bottom: 0px;
                    width: 86px;
                  }
                `}
              />
            </Link>
            <Img
              fluid={this.props.additionalLogo as FluidObject}
              css={css`
                margin-right: 40px;
                position: relative;
                width: 90px;
                bottom: 5px;
                @media (min-width: 1024px) {
                  bottom: 0px;
                  width: 72px;
                }
              `}
            />
          </div>
        </div>
      </header>
    );
  }
}

export default HeaderPadiUmkm;
